<script>
import { mapGetters } from "vuex";

export default {
  name: "AppInstitucionalPage",

  computed: {
    ...mapGetters(["langs", "storeInfo"]),

    /**
     * Lista as páginas institucionais que retornam da API
     * @return {array}
     */
    pages() {
      return this.storeInfo?.custom_pages?.data;
    },

    /**
     * Seleciona o conteúdo do componente de acordo com a rota
     * @return {object}
     */
    pageContent() {
      const currentRouteName = this.$route.params.name;

      if (!this.pages) {
        return;
      }

      return this.pages.find((page) => page.url?.slice(1) === currentRouteName);
    },
  },

  watch: {
    storeInfo() {
      if (!this.pageContent) {
        this.$router.push({ name: "login.main" });
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="pageContent" class="app__login__institucional">
      <span
        class="app__login__institucional__title"
        v-html="pageContent.name"
      />

      <p
        class="app__login__institucional__content"
        v-html="pageContent.content"
      />

      <router-link
        class="app__button--primary-outline app__login__institucional__button"
        :to="{ name: 'login.main' }"
      >
        {{ this.langs.login["button-login-register"] }}
      </router-link>
    </div>
  </div>
</template>
